import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserNavbar from "./UserNavbar";
import LicenseInformation from "./LicenseInformation";
import SystemTable from "./SystemTable";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";

const Configuration = () => {
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const customerkey = Cookies.get("customerkey");
  const username = Cookies.get("username");
  const { licenseInfo, setActiveNav } = useDashboard();
  const [isOpen, setIsOpen] = useState(false);
  const [credentials, setCredentials] = useState({
    agentname: "",
    agentip: "",
    agentgroup: "",
    agentlocation: "",
  });
  const [error, setError] = useState("");
  const { agentsList, setAgentsList } = useDashboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    setActiveNav("config");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { agentname, agentip, agentgroup, agentlocation } = credentials;
    axios
      .post(
        "/api/system/addagent",
        {
          customerid,
          agentname: `${customerkey}-${agentname}`,
          agentip,
          agentgroup,
          agentlocation,
          customername: username,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.message) {
          if (res.data.message != "New Agent Added successfully") {
            setError(res.data.message);
            setTimeout(() => {
              setError("");
            }, 1000);
          } else {
            setCredentials({
              agentname: "",
              agentip: "",
              agentgroup: "",
              agentlocation: "",
            });

            setAgentsList([
              ...agentsList,
              {
                agentgroup,
                agentid: res.data.id,
                agentip,
                agentkey: res.data.key,
                agentlocation,
                agentname: `${customerkey}-${agentname}`,
              },
            ]);

            setIsOpen(false);
          }
        } else {
          setError(res.data);
          setTimeout(() => {
            setError("");
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          setError("");
        }, 1500);
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="overflow-hidden lg:flex">
      <UserNavbar />

      <div className="flex flex-col px-4 mt-16 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 h-full">
        <LicenseInformation />
        <SystemTable />
        {licenseInfo[0]?.available > 0 ? (
          <button
            className="px-6 py-3 rounded-md cursor-pointer text-white bg-[#030b3a] hover:bg-gray-300 hover:text-black md:w-[25%] md:self-center"
            type="button"
            onClick={() => setIsOpen(true)}
          >
            Add System
          </button>
        ) : (
          ""
        )}
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-md z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md relative">
            <div
              className="absolute top-2 right-2 cursor-pointer text-gray-700 text-2xl"
              onClick={() => setIsOpen(false)}
            >
              &times;
            </div>
            <form onSubmit={handleSubmit}>
              <h2 className="text-lg font-semibold mb-4">Add New System</h2>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1">
                  Name
                  <input
                    type="text"
                    id="name"
                    name="agentname"
                    placeholder="Enter system's name"
                    value={credentials.agentname}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
              <div className="mb-4">
                <label htmlFor="ip" className="block mb-1">
                  IP ADDR
                  <input
                    type="text"
                    id="ip"
                    placeholder="Enter system's IP"
                    name="agentip"
                    value={credentials.agentip}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
              <div className="mb-4">
                <label htmlFor="group" className="block mb-1">
                  Group
                  <input
                    type="text"
                    id="group"
                    name="agentgroup"
                    placeholder="Enter group"
                    value={credentials.agentgroup}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
              <div className="mb-4">
                <label htmlFor="location" className="block mb-1">
                  Location
                  <input
                    type="text"
                    id="location"
                    name="agentlocation"
                    placeholder="Enter location"
                    value={credentials.agentlocation}
                    onChange={handleChange}
                    required
                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
              <div className="flex justify-between mt-8">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="bg-gray-400 text-white px-6 py-2 rounded-md hover:bg-red-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#030B3A] text-white px-6 py-2 rounded-md hover:bg-[#B2EBFF] hover:text-black"
                >
                  ADD
                </button>
              </div>
            </form>
            {error && <div className="text-red-500 mt-2">{error}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Configuration;
