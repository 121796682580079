import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";

export default function Map({ geoLocationData }) {
  const position = [8.7832, 34.5085];
  let indiaBoundaryLines = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.7541731, 32.9466018],
            [73.8076029, 32.9423743],
            [73.8313682, 32.9287784],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.6713786, 33.2047351],
            [73.6755463, 33.1954493],
            [73.6629287, 33.1586631],
            [73.6375765, 33.1539475],
            [73.6300569, 33.1355147],
            [73.6355715, 33.1041905],
            [73.6553984, 33.0780875],
            [73.6997514, 33.0736643],
            [73.7346844, 33.064026],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.7511423, 33.0500452],
            [73.7452654, 33.0346563],
            [73.7608652, 33.0167016],
            [73.7602995, 32.9945073],
            [73.784266, 32.9666077],
            [73.7541731, 32.9466018],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.7346844, 33.064026],
            [73.7511423, 33.0500452],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.126488, 35.124629],
            [74.080276, 35.151099],
            [74.044984, 35.141381],
            [73.994706, 35.176384],
            [73.955261, 35.183326],
            [73.930268, 35.20305],
            [73.891099, 35.211663],
            [73.849716, 35.207772],
            [73.804703, 35.236106],
            [73.781098, 35.240546],
            [73.730545, 35.21971],
            [73.721374, 35.229431],
            [73.699706, 35.297219],
            [73.697206, 35.362769],
            [73.709426, 35.413605],
            [73.721923, 35.42749],
            [73.764436, 35.449433],
            [73.80304, 35.503052],
            [73.780273, 35.522491],
            [73.741653, 35.522218],
            [73.726379, 35.529716],
            [73.673035, 35.532768],
            [73.570832, 35.555267],
            [73.534715, 35.553321],
            [73.493043, 35.568603],
            [73.414429, 35.573325],
            [73.347488, 35.599435],
            [73.295823, 35.607498],
            [73.23027, 35.643883],
            [73.2061, 35.665268],
            [73.157212, 35.69027],
            [73.126084, 35.722763],
            [73.113038, 35.751107],
            [73.114989, 35.79222],
            [73.124696, 35.82888],
            [73.114989, 35.847772],
            [73.092483, 35.860275],
            [73.058868, 35.861107],
            [73.008332, 35.840827],
            [72.976379, 35.833878],
            [72.878312, 35.843605],
            [72.825272, 35.856102],
            [72.800537, 35.854714],
            [72.764435, 35.838883],
            [72.688583, 35.818603],
            [72.670822, 35.81833],
            [72.618867, 35.837769],
            [72.570267, 35.848601],
            [72.549148, 35.881104],
            [72.5107111, 35.8960973],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [72.7698226, 36.3078837],
            [72.8053899, 36.3432476],
            [72.8558265, 36.373232],
            [72.8756919, 36.3906618],
            [72.8671272, 36.4113425],
            [72.8785468, 36.4385255],
            [72.9047167, 36.4593849],
            [72.9264853, 36.4530703],
            [72.9474213, 36.4603416],
            [72.9728775, 36.4545054],
            [72.9862003, 36.4680906],
            [72.9747807, 36.4946799],
            [72.9798958, 36.5123692],
            [73.0511493, 36.5322529],
            [73.0586435, 36.5678969],
            [73.0764866, 36.5713362],
            [73.0802931, 36.5939744],
            [73.0969467, 36.6067712],
            [73.0760108, 36.6213798],
            [73.0689925, 36.6792141],
            [73.1086042, 36.6856057],
            [73.1568996, 36.7084011],
            [73.1739101, 36.7265182],
            [73.2377885, 36.707066],
            [73.3082094, 36.7030605],
            [73.3707793, 36.7334778],
            [73.4151493, 36.7326198],
            [73.4435793, 36.7211788],
            [73.4579728, 36.7360517],
            [73.5000826, 36.7134552],
            [73.5631283, 36.7162206],
            [73.579544, 36.7243253],
            [73.5948891, 36.6948582],
            [73.6398538, 36.6981011],
            [73.6900524, 36.6907567],
            [73.7090851, 36.713646],
            [73.7990145, 36.6997225],
            [73.8241139, 36.7115481],
            [73.8619413, 36.6994363],
            [73.8825204, 36.7033466],
            [73.8890629, 36.7282343],
            [73.8818066, 36.7692193],
            [73.8482616, 36.7917994],
            [73.8151923, 36.7991341],
            [73.7955648, 36.8196105],
            [73.7641609, 36.8146586],
            [73.7478642, 36.8272282],
            [73.7050407, 36.8351307],
            [73.654106, 36.908791],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5862409, 33.4257503],
            [73.590003, 33.412472],
            [73.5663406, 33.3718378],
            [73.5724287, 33.3425105],
            [73.5956654, 33.3240186],
            [73.6103332, 33.2882871],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.6103332, 33.2882871],
            [73.6155971, 33.2479462],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.6155971, 33.2479462],
            [73.6366118, 33.210103],
            [73.6713786, 33.2047351],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5733914, 33.6752806],
            [73.5670237, 33.6668826],
            [73.5839324, 33.6174785],
            [73.6054284, 33.5888159],
            [73.629182, 33.5695089],
            [73.6225731, 33.5438847],
            [73.6387736, 33.5337437],
            [73.6103298, 33.5117724],
            [73.6040033, 33.4938069],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.6212449, 33.4758396],
            [73.6314493, 33.4486554],
            [73.595894, 33.4380221],
            [73.5862409, 33.4257503],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.6040033, 33.4938069],
            [73.6212449, 33.4758396],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.575941, 33.9109027],
            [73.5939142, 33.8872066],
            [73.5596079, 33.7813447],
            [73.5985897, 33.747968],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5986353, 33.7452151],
            [73.6067547, 33.7259531],
            [73.6010127, 33.7014501],
            [73.5733914, 33.6752806],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5342636, 33.9691291],
            [73.5461669, 33.9407682],
            [73.575941, 33.9109027],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5136885, 34.0013148],
            [73.5342636, 33.9691291],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.610535, 34.570831],
            [73.641098, 34.566941],
            [73.658034, 34.595824],
            [73.674423, 34.645828],
            [73.661102, 34.684159],
            [73.67276, 34.703324],
            [73.709153, 34.732208],
            [73.720535, 34.767213],
            [73.760269, 34.799438],
            [73.799423, 34.803604],
            [73.833879, 34.826942],
            [73.89833, 34.824716],
            [73.937194, 34.849433],
            [73.986373, 34.870544],
            [74.00444, 34.905265],
            [74.05165, 34.955552],
            [74.055816, 34.996102],
            [74.085265, 35.018326],
            [74.086655, 35.029159],
            [74.062196, 35.060547],
            [74.060256, 35.074714],
            [74.126488, 35.124629],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.3910239, 34.376181],
            [73.398331, 34.38234],
            [73.406937, 34.424438],
            [73.432206, 34.479158],
            [73.442749, 34.554994],
            [73.464707, 34.571937],
            [73.526093, 34.57361],
            [73.581665, 34.587768],
            [73.610535, 34.570831],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.3910239, 34.376181],
            [73.4029651, 34.3530396],
            [73.4433592, 34.3212751],
            [73.4594363, 34.2883684],
            [73.4856852, 34.2727067],
            [73.473291, 34.260812],
            [73.4800625, 34.233512],
            [73.4968165, 34.2088658],
            [73.4903309, 34.1830069],
            [73.4908513, 34.1264472],
            [73.5004697, 34.0946105],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.5136885, 34.0013148],
            [73.4986167, 34.0716161],
            [73.5004697, 34.0946105],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [72.5107111, 35.8960973],
            [72.5088281, 35.9160773],
            [72.523606, 35.926942],
            [72.566941, 36.007507],
            [72.559402, 36.033341],
            [72.521912, 36.072228],
            [72.522475, 36.106948],
            [72.544692, 36.15499],
            [72.540253, 36.202476],
            [72.567215, 36.248298],
            [72.614151, 36.267174],
            [72.663315, 36.2594],
            [72.686357, 36.273277],
            [72.7070145, 36.27311],
            [72.735088, 36.2990642],
            [72.7698226, 36.3078837],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.8313682, 32.9287784],
            [73.8924503, 32.9246253],
            [73.9303692, 32.933448],
            [73.9871521, 32.9323311],
            [74.0097625, 32.9426328],
            [74.0799088, 32.9101082],
            [74.1349048, 32.8991909],
            [74.183487, 32.865696],
            [74.2369804, 32.8475213],
            [74.253395, 32.846275],
            [74.314689, 32.8204231],
            [74.3275444, 32.7829551],
            [74.3564689, 32.7752404],
            [74.378952, 32.801624],
            [74.397241, 32.8009687],
            [74.408611, 32.771667],
            [74.4638194, 32.7766375],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.389758, 32.918015],
            [79.40078, 32.940565],
            [79.379557, 32.945744],
            [79.334741, 32.997603],
            [79.341835, 33.014705],
            [79.328358, 33.034464],
            [79.340579, 33.057364],
            [79.359082, 33.063018],
            [79.373109, 33.118749],
            [79.400529, 33.148002],
            [79.395886, 33.169414],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.4148053, 32.5422657],
            [79.46295, 32.606116],
            [79.502924, 32.643717],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.502924, 32.643717],
            [79.524636, 32.651546],
            [79.548375, 32.676552],
            [79.540843, 32.703226],
            [79.561282, 32.714367],
            [79.543609, 32.731088],
            [79.557271, 32.759179],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.557271, 32.759179],
            [79.5447607, 32.7730679],
            [79.505323, 32.783541],
            [79.4808, 32.800677],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.4808, 32.800677],
            [79.460914, 32.833437],
            [79.470847, 32.84268],
            [79.472568, 32.876263],
            [79.446354, 32.89098],
            [79.421025, 32.895212],
            [79.389758, 32.918015],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.9980541, 36.484496],
            [76.0258665, 36.4860462],
            [76.0692272, 36.4775464],
            [76.1251036, 36.4374592],
            [76.1731349, 36.390302],
            [76.1986356, 36.3874455],
            [76.2547611, 36.3477937],
            [76.2973903, 36.3504645],
            [76.3241353, 36.361788],
            [76.352408, 36.3598739],
            [76.4011526, 36.3215115],
            [76.432004, 36.308161],
            [76.4500366, 36.2694054],
            [76.4324252, 36.252071],
            [76.4560238, 36.2413969],
            [76.4817068, 36.2114093],
            [76.5448627, 36.192757],
            [76.5629732, 36.1730653],
            [76.6011647, 36.1655489],
            [76.6372798, 36.1849242],
            [76.6647957, 36.1580606],
            [76.6972733, 36.1358971],
            [76.7168607, 36.0872442],
            [76.7260034, 36.0784771],
            [76.8007285, 36.0489926],
            [76.8049865, 36.0168814],
            [76.7682293, 36.0080173],
            [76.7543408, 35.9826725],
            [76.7227707, 35.9534305],
            [76.7856816, 35.8899445],
            [76.8037794, 35.8504117],
            [76.8653581, 35.8264735],
            [76.8949429, 35.7997362],
            [76.9352244, 35.7840569],
            [77.004433, 35.7750916],
            [77.033321, 35.7764174],
            [77.081515, 35.7977912],
            [77.1195362, 35.796414],
            [77.1416322, 35.7714596],
            [77.1658828, 35.7586352],
            [77.1753165, 35.7317285],
            [77.2221123, 35.727116],
            [77.2245821, 35.714978],
            [77.2854571, 35.7110688],
            [77.3165673, 35.7000154],
            [77.327599, 35.7190901],
            [77.3584377, 35.71772],
            [77.3832012, 35.6931332],
            [77.4040978, 35.6611799],
            [77.4269413, 35.6593261],
            [77.4379117, 35.6410899],
            [77.4271369, 35.6105445],
            [77.4568505, 35.5936084],
            [77.4655989, 35.5733167],
            [77.4606132, 35.5477076],
            [77.4836923, 35.5363824],
            [77.4724145, 35.5211137],
            [77.4402307, 35.518059],
            [77.406699, 35.5298165],
            [77.3783943, 35.5503481],
            [77.3453946, 35.5468226],
            [77.3227022, 35.5367978],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.9526061, 36.5372951],
            [75.9874928, 36.502322],
            [75.9980541, 36.484496],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.9054981, 34.0051852],
            [78.9354793, 34.0204138],
            [78.9952038, 34.036832],
            [79.0189984, 34.0161307],
            [79.0520729, 34.0025678],
            [79.1087041, 33.9901946],
            [79.1472513, 33.9989986],
            [79.171046, 34.0218414],
            [79.1938888, 34.0249347],
            [79.2252977, 34.010658],
            [79.2488543, 34.0239829],
            [79.2969194, 34.0094682],
            [79.3161931, 34.014941],
            [79.3674407, 33.9946664],
            [79.3906702, 33.997095],
            [79.4101818, 34.0101821],
            [79.4044711, 34.0273142],
            [79.4294554, 34.0596749],
            [79.4161304, 34.1001257],
            [79.4382594, 34.116544],
            [79.4701442, 34.1160681],
            [79.4913214, 34.134152],
            [79.497508, 34.1741269],
            [79.4905886, 34.1908996],
            [79.5586601, 34.209343],
            [79.5898311, 34.2409898],
            [79.6073639, 34.2382215],
            [79.6100777, 34.2841617],
            [79.6013549, 34.3084887],
            [79.5732938, 34.3214156],
            [79.5590171, 34.3716222],
            [79.5347804, 34.4007544],
            [79.5305915, 34.4426436],
            [79.5091611, 34.4511975],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.0851428, 33.6195384],
            [79.0825254, 33.6492817],
            [79.0557405, 33.6653971],
            [79.0220871, 33.674266],
            [79.0079801, 33.6924626],
            [79.0096535, 33.7097317],
            [79.0282783, 33.7536762],
            [79.0004386, 33.7734257],
            [78.9933002, 33.7946029],
            [78.9652226, 33.8212529],
            [78.9371449, 33.9126242],
            [78.8871763, 33.9725866],
            [78.9054981, 34.0051852],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.395886, 33.169414],
            [79.412751, 33.176773],
            [79.38518, 33.201913],
            [79.362314, 33.19727],
            [79.33569, 33.204029],
            [79.323878, 33.190291],
            [79.255818, 33.215399],
            [79.238447, 33.233117],
            [79.216971, 33.237886],
            [79.204401, 33.20984],
            [79.182293, 33.212241],
            [79.151971, 33.181869],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.9366127, 33.3869223],
            [78.9273846, 33.440603],
            [78.9314297, 33.4867645],
            [78.9097505, 33.5654311],
            [78.8883614, 33.572663],
            [78.9077147, 33.621128],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.9077147, 33.621128],
            [78.9499895, 33.6176348],
            [79.0035273, 33.6074032],
            [79.0251804, 33.6190625],
            [79.0851428, 33.6195384],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.5091611, 34.4511975],
            [79.5381633, 34.4788814],
            [79.5631331, 34.457944],
            [79.604798, 34.4653105],
            [79.6233578, 34.449844],
            [79.6735645, 34.454603],
            [79.6849859, 34.4767319],
            [79.7033077, 34.4862498],
            [79.7216296, 34.4717351],
            [79.7526815, 34.4776837],
            [79.7655306, 34.4705453],
            [79.7967052, 34.4822221],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.9079287, 34.6946378],
            [79.9323937, 34.6918877],
            [79.9515387, 34.6737465],
            [79.9721896, 34.6997035],
            [80.0152838, 34.6936803],
            [80.0741464, 34.7058061],
            [80.0792055, 34.733439],
            [80.0669116, 34.7622563],
            [80.0989921, 34.7745363],
            [80.1204618, 34.837705],
            [80.1674635, 34.8800732],
            [80.1969966, 34.8871385],
            [80.2060403, 34.9114433],
            [80.1862573, 34.9464874],
            [80.1975165, 34.96062],
            [80.226635, 34.9724685],
            [80.2335754, 34.9895891],
            [80.2173653, 35.0025938],
            [80.1936721, 35.1184343],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.7967052, 34.4822221],
            [79.8107404, 34.5402635],
            [79.7882498, 34.5640188],
            [79.8018136, 34.5892643],
            [79.787018, 34.605969],
            [79.7865473, 34.6273539],
            [79.8297132, 34.6460687],
            [79.8452731, 34.6690141],
            [79.8984776, 34.69619],
            [79.9079287, 34.6946378],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.1571897, 35.5509472],
            [78.1297226, 35.5455394],
            [78.0766144, 35.5599648],
            [78.0482686, 35.5782463],
            [78.0224262, 35.5642328],
            [77.9978907, 35.5603415],
            [77.9672604, 35.5681972],
            [77.9378503, 35.559317],
            [77.9283455, 35.5412573],
            [77.9338061, 35.5224453],
            [77.9240974, 35.4972106],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.2510871, 35.7282772],
            [78.2401006, 35.7062087],
            [78.2093646, 35.6740077],
            [78.1817008, 35.6673563],
            [78.1960618, 35.6496696],
            [78.1617348, 35.5929304],
            [78.1571897, 35.5509472],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.8035393, 35.8770789],
            [78.8002418, 35.8698246],
            [78.7455044, 35.8594926],
            [78.7217629, 35.8630099],
            [78.6946747, 35.8496793],
            [78.676115, 35.8530106],
            [78.6566034, 35.8220776],
            [78.6075865, 35.8151771],
            [78.5767354, 35.7972152],
            [78.5587882, 35.7733295],
            [78.5225857, 35.7627436],
            [78.5053525, 35.771209],
            [78.462723, 35.7644065],
            [78.4405012, 35.7807327],
            [78.4211347, 35.767396],
            [78.4087559, 35.7306959],
            [78.3423159, 35.7199],
            [78.3195253, 35.7210563],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.3195253, 35.7210563],
            [78.2510871, 35.7282772],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.8558586, 35.9742433],
            [78.836074, 35.9419285],
            [78.8277205, 35.9085145],
            [78.8035393, 35.8770789],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.7042776, 35.6390451],
            [79.7004922, 35.6594497],
            [79.676768, 35.6778252],
            [79.6786294, 35.7002396],
            [79.6645914, 35.7185434],
            [79.6451242, 35.7238949],
            [79.6424872, 35.7501872],
            [79.6295668, 35.7782117],
            [79.5925417, 35.7888788],
            [79.56427, 35.8297913],
            [79.5883037, 35.877887],
            [79.5664131, 35.8982187],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.3664698, 35.5251075],
            [80.3294639, 35.534929],
            [80.3021734, 35.5340292],
            [80.2974544, 35.5560939],
            [80.2238609, 35.5550776],
            [80.2054132, 35.5751497],
            [80.1713024, 35.557166],
            [80.1659653, 35.5466079],
            [80.1825866, 35.5088458],
            [80.1419485, 35.4979942],
            [80.1271319, 35.4783793],
            [80.1099998, 35.4783793],
            [80.0884656, 35.4381664],
            [80.0722853, 35.4231758],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.1936721, 35.1184343],
            [80.2374457, 35.1543721],
            [80.2223395, 35.1780178],
            [80.2468013, 35.1856643],
            [80.2563285, 35.2041814],
            [80.2852472, 35.211262],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.2852472, 35.211262],
            [80.2942892, 35.2678932],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.0722853, 35.4231758],
            [80.0349278, 35.4386423],
            [80.0168439, 35.4657682],
            [80.0068501, 35.4962253],
            [79.994001, 35.5097882],
            [79.9645318, 35.5714719],
            [80.0010814, 35.5924701],
            [79.9545853, 35.6162313],
            [79.957585, 35.6328089],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.957585, 35.6328089],
            [79.9245878, 35.620652],
            [79.9209565, 35.604706],
            [79.8675304, 35.5974051],
            [79.8607658, 35.5766603],
            [79.8367138, 35.5862811],
            [79.8392693, 35.6016142],
            [79.816842, 35.6269739],
            [79.7714728, 35.6256662],
            [79.7340147, 35.642769],
            [79.7042776, 35.6390451],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.2925906, 35.3604184],
            [80.3467536, 35.3910228],
            [80.3769176, 35.3923438],
            [80.4119255, 35.4260306],
            [80.4208426, 35.4430942],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.2942892, 35.2678932],
            [80.2662115, 35.2964467],
            [80.2925906, 35.3604184],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [80.4208426, 35.4430942],
            [80.4115952, 35.4771112],
            [80.3894676, 35.4876797],
            [80.3660968, 35.5134914],
            [80.3664698, 35.5251075],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.5664131, 35.8982187],
            [79.5578387, 35.8803554],
            [79.5253079, 35.8657997],
            [79.5013308, 35.8465243],
            [79.4717125, 35.8586372],
            [79.4522501, 35.8827864],
            [79.468368, 35.9003393],
            [79.4563342, 35.9240926],
            [79.4396228, 35.9341975],
            [79.4369169, 35.9722297],
            [79.3920214, 35.9762218],
            [79.3434392, 35.9878727],
            [79.3383831, 35.9634717],
            [79.3131028, 35.9568768],
            [79.2695767, 35.9274197],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.2695767, 35.9274197],
            [79.1862615, 35.9331353],
            [79.1644984, 35.9447862],
            [79.077666, 35.9469845],
            [79.0572219, 35.9414888],
            [79.0257863, 35.9151093],
            [79.0114975, 35.9311568],
            [78.9635747, 35.9370922],
            [78.9547816, 35.9485233],
            [78.9264237, 35.9452259],
            [78.8558586, 35.9742433],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.841253, 36.925945],
            [73.827306, 36.899294],
            [73.799916, 36.890602],
            [73.7521, 36.912051],
            [73.734868, 36.903018],
            [73.69158, 36.915797],
            [73.654106, 36.908791],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.9999254, 36.9979211],
            [74.9189999, 36.978957],
            [74.931881, 36.945459],
            [74.9060629, 36.92974],
            [74.8837271, 36.936386],
            [74.8801829, 36.960527],
            [74.8402621, 37.019909],
            [74.836669, 37.058713],
            [74.805633, 37.053186],
            [74.794419, 37.031096],
            [74.7741229, 37.022023],
            [74.739209, 37.021655],
            [74.717482, 37.037366],
            [74.7214841, 37.05441],
            [74.7019699, 37.082359],
            [74.667951, 37.082416],
            [74.622406, 37.059823],
            [74.624373, 37.047285],
            [74.5801222, 37.030568],
            [74.564626, 37.030852],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.065299, 36.841091],
            [74.048114, 36.826411],
            [74.003385, 36.836827],
            [73.99436, 36.830229],
            [73.95743, 36.842518],
            [73.932234, 36.87474],
            [73.914651, 36.869367],
            [73.892046, 36.879803],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.892046, 36.879803],
            [73.90677, 36.900804],
            [73.890465, 36.912521],
            [73.841253, 36.925945],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.153365, 36.898165],
            [74.129893, 36.8637],
            [74.12079, 36.83822],
            [74.065299, 36.841091],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.315913, 36.938305],
            [74.309291, 36.918906],
            [74.237211, 36.894872],
            [74.223558, 36.896831],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.223558, 36.896831],
            [74.180747, 36.916499],
            [74.153365, 36.898165],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.534055, 36.957221],
            [74.506271, 36.966017],
            [74.491484, 36.988002],
            [74.426558, 37.01115],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.395311, 36.973441],
            [74.388036, 36.964049],
            [74.350169, 36.962302],
            [74.315913, 36.938305],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.426558, 37.01115],
            [74.413732, 36.975273],
            [74.395311, 36.973441],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.564626, 37.030852],
            [74.51893, 37.017367],
            [74.543524, 36.967085],
            [74.534055, 36.957221],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.19221, 36.974688],
            [75.2162911, 36.971826],
            [75.233421, 36.959055],
            [75.285346, 36.974495],
            [75.315789, 36.969826],
            [75.327187, 36.958537],
            [75.3589271, 36.956816],
            [75.3753049, 36.946841],
            [75.3995239, 36.955057],
            [75.4058632, 36.936584],
            [75.387195, 36.925916],
            [75.385606, 36.903406],
            [75.4202048, 36.889483],
            [75.4318285, 36.8301725],
            [75.419898, 36.7994229],
            [75.4195976, 36.7733706],
            [75.4435014, 36.7467937],
            [75.4514837, 36.7246105],
            [75.4694223, 36.7159075],
            [75.4992641, 36.739151],
            [75.5324539, 36.721379],
            [75.5339051, 36.771441],
            [75.5736412, 36.766664],
            [75.6373748, 36.770191],
            [75.673418, 36.764956],
            [75.6981101, 36.74689],
            [75.7195099, 36.752095],
            [75.7658138, 36.724879],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.19221, 36.974688],
            [75.1581259, 36.992952],
            [75.168135, 37.009677],
            [75.1376891, 37.026454],
            [75.1254728, 37.012544],
            [75.0480739, 37.00475],
            [75.0292459, 37.01624],
            [74.9999254, 36.9979211],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.7658138, 36.724879],
            [75.803792, 36.710235],
            [75.8487161, 36.673665],
            [75.864847, 36.669735],
            [75.888166, 36.639054],
            [75.911314, 36.629027],
            [75.9406328, 36.5928932],
            [75.9257841, 36.5730437],
            [75.9526061, 36.5372951],
          ],
        },
        properties: {
          boundary: "claimed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9905304, 34.270513],
            [73.9457114, 34.275773],
            [73.9355039, 34.2832134],
            [73.9436386, 34.3087704],
            [73.9285029, 34.3184594],
            [73.939008, 34.336531],
            [73.902775, 34.3435091],
            [73.859152, 34.3240239],
            [73.7989322, 34.3380486],
            [73.7741744, 34.3323553],
            [73.7577291, 34.3453756],
            [73.7559108, 34.3808906],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.7559108, 34.3808906],
            [73.8028158, 34.4199405],
            [73.8408422, 34.4260503],
            [73.8767736, 34.4509386],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.0068582, 33.2196316],
            [74.0357634, 33.1968812],
            [74.0704198, 33.194053],
            [74.0863584, 33.1735885],
            [74.1095092, 33.1679721],
            [74.1538739, 33.1366972],
            [74.1598133, 33.11104],
            [74.1821322, 33.0891048],
            [74.1705642, 33.0737326],
            [74.2016087, 33.0667481],
            [74.2364609, 33.0508369],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.2364609, 33.0508369],
            [74.3135502, 33.0310221],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.3398618, 32.9533037],
            [74.312081, 32.9360384],
            [74.3261089, 32.9200999],
            [74.3492939, 32.9218382],
            [74.3918595, 32.9104138],
            [74.4202495, 32.8855143],
            [74.4143485, 32.8661322],
            [74.4193573, 32.840028],
            [74.4368055, 32.8246231],
            [74.4482712, 32.7935718],
            [74.4638194, 32.7766375],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.3200092, 33.0102828],
            [74.3398618, 32.9533037],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.3196175, 33.0221046],
            [74.3200092, 33.0102828],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.3135502, 33.0310221],
            [74.3247504, 33.0309118],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.3247504, 33.0309118],
            [74.3196175, 33.0221046],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.4148053, 32.5422657],
            [79.3993521, 32.5656677],
            [79.3988585, 32.6038733],
            [79.4402426, 32.6878901],
            [79.4607388, 32.7100065],
            [79.4397771, 32.7278151],
            [79.414725, 32.7369904],
            [79.4168006, 32.7543432],
            [79.4069967, 32.7854596],
            [79.3909108, 32.7919376],
            [79.3658191, 32.833506],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.3658191, 32.833506],
            [79.3226366, 32.8698925],
            [79.3028385, 32.8922381],
            [79.3062838, 32.9279279],
            [79.2851028, 32.9500552],
            [79.2372676, 32.974825],
            [79.2218542, 32.9636307],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.1612899, 33.0134858],
            [79.1693728, 32.9988535],
            [79.2218542, 32.9636307],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.162648, 33.167327],
            [79.1467094, 33.1467503],
            [79.1400575, 33.1057784],
            [79.1612899, 33.0134858],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1284842, 33.3059388],
            [74.0785711, 33.2857249],
            [74.0179945, 33.2704972],
            [74.0004574, 33.236454],
            [74.0068582, 33.2196316],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1712664, 33.3653323],
            [74.1662856, 33.3377332],
            [74.1284842, 33.3059388],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.0372165, 33.5765835],
            [74.0811754, 33.5847842],
            [74.0966297, 33.5710433],
            [74.1406958, 33.5577297],
            [74.1595483, 33.5321953],
            [74.1569727, 33.5123503],
            [74.1924993, 33.4787338],
            [74.1771336, 33.432665],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1771336, 33.432665],
            [74.1789735, 33.412343],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1789735, 33.412343],
            [74.1866703, 33.3848975],
            [74.1712664, 33.3653323],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9777549, 33.7328969],
            [73.9614172, 33.7234751],
            [73.9813147, 33.7067968],
            [73.9857477, 33.6690023],
            [73.9811116, 33.6414466],
            [74.014145, 33.6071629],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.014145, 33.6071629],
            [74.0372165, 33.5765835],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.0464755, 33.8103853],
            [74.0099259, 33.7763602],
            [73.9996523, 33.7412981],
            [73.9777549, 33.7328969],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.2662591, 33.986617],
            [74.2707054, 33.9563714],
            [74.2820286, 33.935202],
            [74.2739231, 33.917668],
            [74.2812899, 33.9010507],
            [74.2336106, 33.8715024],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.2336106, 33.8715024],
            [74.1894755, 33.8389396],
            [74.1297257, 33.827057],
            [74.0602091, 33.8239262],
            [74.0464755, 33.8103853],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.2560584, 34.0049691],
            [74.2662591, 33.986617],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.8998363, 34.0501952],
            [73.9075201, 34.0826857],
            [73.904503, 34.1047418],
            [73.9255716, 34.1028872],
            [73.9268231, 34.1274427],
            [73.9536254, 34.1479357],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.0161109, 34.0396137],
            [74.0613719, 34.0348241],
            [74.1474396, 34.0419243],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9536254, 34.1479357],
            [73.9580405, 34.1671387],
            [73.9897933, 34.1758194],
            [74.0037106, 34.1906613],
            [74.008404, 34.2212038],
            [73.9897633, 34.2590814],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1468434, 34.6642175],
            [74.1577744, 34.688635],
            [74.2089801, 34.7170025],
            [74.2414768, 34.7270764],
            [74.309727, 34.7825041],
            [74.3604777, 34.7871414],
            [74.3956982, 34.7854894],
            [74.4361693, 34.7741331],
            [74.4484807, 34.7823207],
            [74.4786579, 34.7794668],
            [74.5229199, 34.7670789],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.8998363, 34.0501952],
            [73.9280878, 34.0228528],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9490521, 34.0168724],
            [73.9868638, 34.0131164],
            [74.0161109, 34.0396137],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9280878, 34.0228528],
            [73.9490521, 34.0168724],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.1474396, 34.0419243],
            [74.19725, 34.0167725],
            [74.233124, 34.0236777],
            [74.2560584, 34.0049691],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.9897633, 34.2590814],
            [73.9905304, 34.270513],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [73.8767736, 34.4509386],
            [73.8824438, 34.4837101],
            [73.9050294, 34.5279357],
            [73.9534937, 34.5605739],
            [73.9529139, 34.5977363],
            [73.9284562, 34.6030914],
            [73.9321485, 34.641444],
            [73.9688411, 34.6857401],
            [74.00919, 34.6845391],
            [74.0713441, 34.6693165],
            [74.0946106, 34.6704811],
            [74.1282496, 34.6589373],
            [74.1468434, 34.6642175],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.6039432, 34.7377535],
            [74.6508426, 34.7059418],
            [74.7305659, 34.6811952],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.5229199, 34.7670789],
            [74.5802199, 34.761681],
            [74.6039432, 34.7377535],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.7636011, 34.6782863],
            [74.8419039, 34.6733948],
            [74.9358366, 34.6727606],
            [75.0134107, 34.6394355],
            [75.0251756, 34.6289883],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [74.7305659, 34.6811952],
            [74.7636011, 34.6782863],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.0251756, 34.6289883],
            [75.0791934, 34.6470749],
            [75.1202606, 34.6475955],
            [75.1632344, 34.6565225],
            [75.2055085, 34.6438117],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.2055085, 34.6438117],
            [75.2667819, 34.6367344],
            [75.2477144, 34.6034982],
            [75.3009802, 34.5863107],
            [75.320012, 34.5648026],
            [75.3468414, 34.5602247],
            [75.3664001, 34.5380975],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.3664001, 34.5380975],
            [75.3956106, 34.5475573],
            [75.4248811, 34.546308],
            [75.4628233, 34.5347389],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.4933574, 34.5435476],
            [75.4628233, 34.5347389],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.5296134, 34.5446819],
            [75.5131896, 34.5559637],
            [75.4933574, 34.5435476],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.3378187, 34.7232677],
            [76.2823927, 34.7234544],
            [76.2584918, 34.7171679],
            [76.2700645, 34.6983829],
            [76.2502737, 34.6763435],
            [76.1939388, 34.6613902],
            [76.1633918, 34.6327333],
            [76.1159327, 34.6392682],
            [76.1007729, 34.6477744],
            [76.0631703, 34.687801],
            [76.0335806, 34.6856497],
            [75.9963681, 34.6503647],
            [75.9455708, 34.6255652],
            [75.8928994, 34.5823803],
            [75.8357186, 34.5560429],
            [75.7850992, 34.5136678],
            [75.6989704, 34.525826],
            [75.6801981, 34.5322645],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.6566505, 34.5396156],
            [75.6303429, 34.5371492],
            [75.5407928, 34.5390892],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.5407928, 34.5390892],
            [75.5296134, 34.5446819],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [75.6801981, 34.5322645],
            [75.6566505, 34.5396156],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.683033, 34.7448273],
            [76.6968004, 34.750538],
            [76.7017253, 34.7957801],
            [76.7369127, 34.8064789],
            [76.7465058, 34.8494028],
            [76.745396, 34.8883506],
            [76.7618673, 34.8996986],
            [76.7634058, 34.9307175],
            [76.7922396, 34.9538342],
            [76.8302945, 34.9548731],
            [76.8613212, 34.9482902],
            [76.8979006, 34.9259701],
            [76.9156655, 34.9458057],
            [76.9410488, 34.9947758],
            [76.9609328, 35.017621],
            [77.0058073, 35.023907],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.4429502, 34.7502095],
            [76.4577062, 34.7795521],
            [76.4865853, 34.796098],
            [76.5174837, 34.7831044],
            [76.5412148, 34.762017],
            [76.5781944, 34.759311],
            [76.6102058, 34.7455187],
            [76.6226954, 34.7615942],
            [76.6631745, 34.7399602],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.3378187, 34.7232677],
            [76.3603306, 34.7331887],
            [76.419387, 34.737644],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.419387, 34.737644],
            [76.4429502, 34.7502095],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.6631745, 34.7399602],
            [76.683033, 34.7448273],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [77.0058073, 35.023907],
            [77.0349455, 35.0372562],
            [77.0468109, 35.0514566],
            [77.1104407, 35.0488686],
            [77.1203914, 35.065026],
            [77.1113362, 35.0891261],
            [77.0823302, 35.0992247],
            [77.0853922, 35.1691654],
            [77.0432982, 35.1823895],
            [77.0174718, 35.1829928],
            [76.9906239, 35.2106346],
            [77.0043311, 35.2324902],
            [76.975079, 35.2513273],
            [76.999455, 35.283328],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [76.999455, 35.283328],
            [77.0066228, 35.2944771],
            [76.9779725, 35.3449705],
            [76.9587121, 35.3627163],
            [76.9478202, 35.3928716],
            [76.9191527, 35.4014004],
            [76.908484, 35.389611],
            [76.8627963, 35.389639],
            [76.8316741, 35.4207355],
            [76.8385577, 35.4428491],
            [76.8156409, 35.4609718],
            [76.7941146, 35.4911883],
            [76.7965436, 35.5169363],
            [76.7589841, 35.5183824],
            [76.7497487, 35.5550366],
            [76.793694, 35.5880725],
            [76.7551389, 35.6296273],
            [76.7821154, 35.6425254],
            [76.775992, 35.658059],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7682083, 33.5276825],
            [78.8036905, 33.4892571],
            [78.8359291, 33.4266235],
            [78.8581479, 33.4160145],
            [78.891523, 33.4142358],
            [78.9366127, 33.3869223],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7172226, 33.6242162],
            [78.7510081, 33.6278404],
            [78.7409785, 33.600818],
            [78.7400436, 33.55377],
            [78.7682083, 33.5276825],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7281399, 33.9233065],
            [78.7623447, 33.9145308],
            [78.7512873, 33.8847988],
            [78.7653737, 33.8358769],
            [78.754213, 33.7842564],
            [78.7752406, 33.7378895],
            [78.763263, 33.7197886],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.696326, 33.6454463],
            [78.7172226, 33.6242162],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7435819, 33.6778044],
            [78.7121701, 33.6629071],
            [78.696326, 33.6454463],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7551349, 33.702452],
            [78.7435819, 33.6778044],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.763263, 33.7197886],
            [78.7551349, 33.702452],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.656517, 34.0318296],
            [78.6978699, 34.0259418],
            [78.7423297, 34.0000135],
            [78.7418471, 33.9769719],
            [78.7281399, 33.9233065],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.2142157, 34.8887954],
            [78.234592, 34.882224],
            [78.2340706, 34.84461],
            [78.2255444, 34.8111977],
            [78.2270274, 34.7723644],
            [78.2123326, 34.7688329],
            [78.2022153, 34.7354448],
            [78.2086386, 34.7207217],
            [78.2720418, 34.7013074],
            [78.2783929, 34.676011],
            [78.2625527, 34.6636588],
            [78.272983, 34.6297702],
            [78.2901044, 34.6151285],
            [78.3296214, 34.6113893],
            [78.3421378, 34.6026121],
            [78.3856301, 34.6065087],
            [78.4292343, 34.5906384],
            [78.4263976, 34.5606846],
            [78.4354847, 34.5419334],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.6696832, 34.0918755],
            [78.6574443, 34.0743787],
            [78.656517, 34.0318296],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.9061058, 34.1410958],
            [78.8620876, 34.1656239],
            [78.8537106, 34.14154],
            [78.8261029, 34.1246806],
            [78.791774, 34.1352605],
            [78.7851736, 34.120189],
            [78.742262, 34.0923607],
            [78.7077821, 34.0947465],
            [78.6811287, 34.0797156],
            [78.6696832, 34.0918755],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.7387265, 34.4593468],
            [78.764039, 34.4397896],
            [78.80594, 34.4355969],
            [78.8245858, 34.4179146],
            [78.8511482, 34.4152844],
            [78.8787246, 34.3908687],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.4354847, 34.5419334],
            [78.460294, 34.5691948],
            [78.4825069, 34.5782819],
            [78.5519156, 34.5715444],
            [78.5597608, 34.552972],
            [78.5636252, 34.5095782],
            [78.5799089, 34.5043863],
            [78.5915317, 34.5281629],
            [78.6342168, 34.5440664],
            [78.6844209, 34.5274222],
            [78.7097062, 34.5258831],
            [78.7152658, 34.5031106],
            [78.756696, 34.4844215],
            [78.7569296, 34.467628],
            [78.7387265, 34.4593468],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.151971, 33.181869],
            [79.162648, 33.167327],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [79.0366745, 34.3348429],
            [79.0286922, 34.3167147],
            [78.9855623, 34.3139853],
            [78.9853436, 34.2986856],
            [78.9648508, 34.2667734],
            [78.9616223, 34.236652],
            [78.9437825, 34.2250029],
            [78.9256764, 34.1544091],
            [78.9061058, 34.1410958],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.9308506, 34.3797259],
            [78.9471597, 34.3892683],
            [78.9819574, 34.3569708],
            [79.0366745, 34.3348429],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.8787246, 34.3908687],
            [78.9021272, 34.3810115],
            [78.9308506, 34.3797259],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.0032132, 35.2394089],
            [78.0114143, 35.302278],
            [78.0229214, 35.3589806],
            [78.0539886, 35.3996825],
            [78.1071934, 35.4391815],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.0032132, 35.2394089],
            [78.0157461, 35.215878],
            [78.052484, 35.1818492],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.1071934, 35.4391815],
            [78.1104294, 35.4700669],
            [78.1371688, 35.4924729],
            [78.0981158, 35.4995308],
            [78.039807, 35.4887695],
            [78.0249689, 35.4682598],
            [77.9947134, 35.4897179],
            [77.9682455, 35.494314],
            [77.9476781, 35.478626],
            [77.9240974, 35.4972106],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.2142157, 34.8887954],
            [78.1785251, 34.9285466],
            [78.202275, 34.9547865],
            [78.2014758, 34.9730838],
            [78.1764697, 34.9879059],
            [78.1579907, 34.9887481],
            [78.1427152, 35.0110978],
            [78.1145094, 35.0318238],
            [78.1396401, 35.0765309],
            [78.1275343, 35.1004602],
            [78.1125788, 35.1069586],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [78.052484, 35.1818492],
            [78.0582875, 35.1350631],
            [78.0492539, 35.1140027],
            [78.0836934, 35.102347],
            [78.1125788, 35.1069586],
          ],
        },
        properties: {
          boundary: "disputed",
        },
      },
    ],
  };

  useEffect(() => {
    let map = L.map("map").setView(position, 2);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    let indiaBoundaries;

    function addIndiaBoundaries() {
      indiaBoundaries = L.geoJSON(indiaBoundaryLines, {
        style: boundaryStyle,
      }).addTo(map);
    }

    addIndiaBoundaries();

    function boundaryStyle(feature) {
      let wt;
      wt = map.getZoom() / 4;
      switch (feature.properties.boundary) {
        case "disputed":
          return {
            color: "#f2efea",
            weight: wt * 2,
          };
        case "claimed":
          return {
            color: "#b9a8b9",
            weight: wt,
          };
      }
    }

    map.on("zoomend", function () {
      indiaBoundaries.removeFrom(map);
      addIndiaBoundaries();
    });

    const points = geoLocationData
      ? geoLocationData.map((p) => {
          return [p.coordinates[0], p.coordinates[1], p.doc_count * 100];
        })
      : [];

    L.heatLayer(points, {
      radius: 20,
      max: 1.0,
      blur: 15,
      gradient: {
        0.0: "green",
        0.5: "yellow",
        1.0: "red",
      },
      minOpacity: 0.7,
    }).addTo(map);

    return () => {
      map.remove();
    };
  }, [geoLocationData]);

  return <div id="map" className="size-96 w-full"></div>;
}
