import React from "react";
import logoheader from "../Images/logo-white.webp";
import { useNavigate, Link } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between px-4 md:px-20 py-4 items-center bg-[#030b3a] text-white">
      <div className="flex items-center">
        <div>
          <img
            className="cursor-pointer w-18 h-16 lg:w-36 lg:h-24 mr-12"
            src={logoheader}
            alt="securweave's logo"
            onClick={() => navigate("/")}
          ></img>
        </div>

        <div className="hidden lg:block">
          <div className="flex gap-4 text-lg lg:gap-8 md:text-sm lg:text-md xl:text-xl">
            <Link className="p-2">Features</Link>
            <Link className="p-2">About Us</Link>
            <Link className="p-2">Pricing</Link>
            <Link className="p-2">Blog</Link>
          </div>
        </div>
      </div>

      <div className="flex">
        <Link to={"/signin"} className="px-4">
          <button className=" px-6 py-2 bg-white rounded-lg text-black cursor-pointer hover:bg-gray-400 hover:text-white">
            Sign In
          </button>
        </Link>
        <button className=" hidden sm:flex px-6 py-2 bg-white rounded-lg text-black cursor-pointer hover:bg-gray-400 hover:text-white">
          Sign Up
        </button>
      </div>
      
    </div>
  );
};

export default Navbar;
