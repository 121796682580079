import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";

const SystemTable = () => {
  const { agentsList, setAgentsList } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert(`Copied to clipboard!`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    axios
      .get(`/api/system/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setAgentsList(res.data.systems));
  }, []);
  return (
    <div className="max-h-[50vh] overflow-y-auto text-sm md:text-md mb-8 overflow-x-auto">
      {agentsList.length > 0 ? (
        <table className="w-full border-separate border-spacing-0 border-black table-fixed overflow-x-scroll">
          <thead className="bg-gray-200 border border-black sticky top-0 z-10">
            <tr>
              <th className="border border-black text-start p-2 w-[50px]">
                ID
              </th>
              <th className="border border-black text-start p-2 w-[150px]">
                SYSTEM
              </th>
              <th className="border border-black text-start p-2 w-[150px]">
                IP ADDR
              </th>
              <th className="border border-black text-start p-2 w-[150px]">
                GROUP
              </th>
              <th className="border border-black text-start p-2 w-[150px]">
                LOCATION
              </th>
              <th className="border border-black text-start p-2 w-[250px]">
                KEY
              </th>
            </tr>
          </thead>
          <tbody>
            {agentsList.map((data) => (
              <tr key={data.agentid}>
                <td className="border border-black p-2 text-start">
                  {data.agentid}
                </td>
                <td className="border border-black p-2 text-start">
                  {data.agentname}
                </td>
                <td className="border border-black p-2 text-start">
                  {data.agentip}
                </td>
                <td className="border border-black p-2 text-start">
                  {data.agentgroup}
                </td>
                <td className="border border-black p-2 text-start">
                  {data.agentlocation}
                </td>
                <td className="border border-black p-2 text-start break-words">
                  <i
                    class="ri-file-copy-line mr-2 font-bold cursor-pointer text-lg"
                    onClick={() => copyToClipboard(data.agentkey)}
                    title="Click to copy!"
                  ></i>
                  {data.agentkey}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No agents added
        </div>
      )}
    </div>
  );
};

export default SystemTable;
