import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDashboard } from "../contexts/DashboardContext";

const LicenseInformation = () => {
  const { licenseInfo, setLicenseInfo } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");

  useEffect(() => {
    axios
      .get(`/api/customer/${customerid}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => setLicenseInfo(res.data.customers));
  }, []);
  return (
    <div className="flex justify-between sm:justify-center sm:gap-8 lg:gap-28 mb-12">
      <div className="py-4 rounded-md flex flex-col gap-4 border border-black px-2">
        <div className="text-xs md:text-lg font-bold">
          <i className="ri-key-2-line mr-2"></i>Total Licenses
        </div>

        {licenseInfo.length > 0 ? (
          <div className="text-xs md:text-lg font-bold text-center">
            {licenseInfo[0].count}
          </div>
        ) : (
          <div>0</div>
        )}
      </div>

      <div className="py-4 rounded-md flex flex-col gap-4 border border-black px-2">
        <div className="text-xs md:text-lg font-bold">
          <i className="ri-key-2-line mr-2"></i>Available Licences
        </div>

        {licenseInfo.length > 0 ? (
          <div className="text-xs md:text-lg font-bold text-center">
            {licenseInfo[0].available}
          </div>
        ) : (
          "0"
        )}
      </div>
    </div>
  );
};

export default LicenseInformation;
