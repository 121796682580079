import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import Footer from "./Footer";
import { useDashboard } from "../contexts/DashboardContext";
import Navbar from "./Navbar";

const SignInPage = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const { setAdmin } = useDashboard();
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove("customerid");
    Cookies.remove("username");
    Cookies.remove("customerkey");
    Cookies.remove("role");
    setAdmin(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = credentials;
    axios
      .post("/api/token", {
        username,
        password,
      })
      .then((res) => {
        Cookies.set("token", res.data.token);
        Cookies.set("customerid", res.data.customerid);
        Cookies.set("username", res.data.username);
        Cookies.set("customerkey", res.data.key);
        Cookies.set("role", res.data.role);
        setCredentials({
          username: "",
          password: "",
        });
        navigate("/dashboard");
      })
      .catch((err) => {
        setError("Invalid Credentials");
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  const handleLoginChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Navbar />

      <div className="min-h-[85vh] py-24 px-4 md:ml-28">
        <h2 className="text-4xl font-bold mb-10">Sign In</h2>
        <form className="flex flex-col">
          <label for="username" className="pb-2">Username</label>
          <input
            className="border border-gray-400 w-[80vw] py-3 px-2 text-lg bg-gray-50 rounded-md mb-6 md:w-[50vw] xl:w-[30vw]"
            type="text"
            placeholder="Username"
            name="username"
            id="username"
            value={credentials.username}
            onChange={handleLoginChange}
            required
          ></input>

          <label for="password" className="pb-2">Password</label>
          <input
            className="border border-gray-400 w-[80vw] py-3 px-2 text-lg bg-gray-50 rounded-md mb-6 md:w-[50vw] xl:w-[30vw]"
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            value={credentials.password}
            onChange={handleLoginChange}
            required
          ></input>

          {error}
          <button
            type="submit"
            onClick={handleLogin}
            className="w-[80vw] md:w-[50vw] xl:w-[30vw] bg-black text-white py-4 rounded-md cursor-pointer text-lg"
          >
            Sign In
          </button>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default SignInPage;
