import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import UserNavbar from "./UserNavbar";

const Logs = () => {
  const [group, setGroup] = useState("");
  const [postsPerPage] = useState(15);
  const [allAlertsData, setAllAlertsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const [tableTab, setTableTab] = useState("table");
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    setGroup(params.group);
  }, []);

  useEffect(() => {
    axios
      .get(`/api/system/systems/table/type/${group}`, {
        params: {
          hours: 24,
        },
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => {
        setAllAlertsData(res.data);
      });
  }, [group]);

  const showAlertType = () => {
    if (group === "syscheck") {
      return "File Integrity Changes";
    } else if (group === "rootcheck") {
      return "Rootkit Alerts";
    } else if (group === "attack") {
      return "Attack Alerts";
    } else if (group === "gt10") {
      return "Alerts level > 10";
    } else {
      return group;
    }
  };

  const showTableTabs = (item) => {
    if (tableTab === "table") {
      return (
        <div className="p-6 text-lg">
          <table className="table-fixed">
            <tbody className="text-center">
              {item._source.agent &&
                Object.entries(item._source.agent).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td className="p-2">agent.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.GeoLocation &&
                item._source.GeoLocation.location &&
                Object.entries(item._source.GeoLocation.location).map(
                  (entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td>GeoLocation.{key}</td>
                        <td>{value}</td>
                      </tr>
                    );
                  }
                )}

              {item._source.decoder &&
                Object.entries(item._source.decoder).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td>decoder.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.input &&
                Object.entries(item._source.input).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td>input.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.manager &&
                Object.entries(item._source.manager).map((entry) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <tr>
                      <td>manager.{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              {item._source.rule && item._source.rule.mitre
                ? Object.entries(item._source.rule.mitre).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td>rule.mitre.{key}</td>
                        {key === "technique" || key === "tactic" ? (
                          <td>{value.map((item) => item + " ")}</td>
                        ) : (
                          <td>{value}</td>
                        )}
                      </tr>
                    );
                  })
                : Object.entries(item._source.rule).map((entry) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <tr>
                        <td>rule.{key}</td>
                        {key === "groups" || key === "tsc" ? (
                          <td>{value.map((item) => item + " ")}</td>
                        ) : (
                          <td>{value}</td>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      );
    } else if (tableTab === "json") {
      return (
        <div>
          <pre>
            <code>{JSON.stringify(item._source, undefined, 2)}</code>
          </pre>
        </div>
      );
    } else if (tableTab === "rule") {
      return <div>RULE</div>;
    }
  };

  const showAlertsData = (alertsData) => {
    const indexOfLastPage = currentPage * postsPerPage;
    const indexOfFirstPage = indexOfLastPage - postsPerPage;
    const currentPosts = alertsData.slice(indexOfFirstPage, indexOfLastPage);

    try {
      return currentPosts.map((item, index) => {
        return (
          <>
            <tr
              key={index}
              onClick={() =>
                dropdownIndex === index
                  ? setDropdownIndex(-1)
                  : setDropdownIndex(index)
              }
              className={`border border-black text-center cursor-pointer ${
                dropdownIndex === index ? "bg-[aliceblue]" : null
              }`}
            >
              <td className="border border-black p-2">
                {dropdownIndex === index ? (
                  <i class="ri-arrow-down-wide-line"></i>
                ) : (
                  <i class="ri-arrow-right-wide-line"></i>
                )}
              </td>
              <td className="border border-black p-2">
                {new Date(item._source.timestamp)
                  .toLocaleString()
                  .replace(",", "")}
              </td>
              <td className="border border-black p-2 text-[#0078a3]">
                {item._source.agent ? item._source.agent.id : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.agent ? item._source.agent.name : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule.mitre && item._source.rule.mitre.technique
                  ? item._source.rule.mitre.technique.map((item) => item + " ")
                  : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule.mitre && item._source.rule.mitre.tactic
                  ? item._source.rule.mitre.tactic.map((item) => item + " ")
                  : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule ? item._source.rule.description : "-"}
              </td>
              <td className="border border-black p-2">
                {item._source.rule ? item._source.rule.level : "-"}
              </td>

              <td className="border border-black p-2 text-[#0078a3]">
                {item._source.rule.id}
              </td>
            </tr>
            {dropdownIndex === index ? (
              <tr>
                <td>
                  <div>
                    <div className="flex gap-6 px-12 py-4">
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "table"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("table")}
                      >
                        Table
                      </div>
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "json"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("json")}
                      >
                        JSON
                      </div>
                      <div
                        className={`text-lg font-semibold ${
                          tableTab === "rule"
                            ? "border-b-2 border-blue-400 text-blue-400"
                            : null
                        } py-2`}
                        onClick={() => setTableTab("rule")}
                      >
                        Rule
                      </div>
                    </div>

                    {showTableTabs(item)}
                  </div>
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const showAlertsPagination = (alertsData) => {
    const totalPosts = alertsData.length;
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const pageNumbers = [];

    // Helper function to add page numbers
    const addPageNumber = (number) => {
      if (number > 0 && number <= totalPages && !pageNumbers.includes(number)) {
        pageNumbers.push(number);
      }
    };

    // Always show the first two pages
    addPageNumber(1);
    addPageNumber(2);

    // Show dots if needed
    if (currentPage > 3) {
      pageNumbers.push("..."); // Dots before the middle
    }

    // Show middle pages
    for (
      let i = Math.max(3, currentPage - 1);
      i <= Math.min(totalPages - 2, currentPage + 1);
      i++
    ) {
      addPageNumber(i);
    }

    // Show dots if needed
    if (currentPage < totalPages - 2) {
      pageNumbers.push("..."); // Dots after the middle
    }

    // Always show the last two pages
    addPageNumber(totalPages - 1);
    addPageNumber(totalPages);

    const handlePageChange = (number) => {
      if (number !== "...") {
        setCurrentPage(number);
      }
    };

    return (
      <div className="mt-5 mb-12">
        <ul className="flex gap-2 flex-wrap justify-center">
          {pageNumbers.map((number, index) => (
            <li key={index}>
              <button
                onClick={() => handlePageChange(number)}
                className={`px-2 py-1 text-sm min-w-8 rounded-sm ${
                  currentPage === number
                    ? "bg-[#05123A] text-white"
                    : "bg-gray-400 text-black"
                }`}
                disabled={number === "..."}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="overflow-hidden lg:flex justify-between">
      <UserNavbar />

      <div className="px-4 pt-6 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 lg:pt-16 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 h-full">
        <div className="text-center m-4 text-xl">
          {showAlertType()} logs for the last 24 hours
        </div>
        <div className="h-[60vh] text-sm md:text-md overflow-auto">
          <table className="text-left border-separate border-spacing-0 border-black table-fixed w-full">
            <thead className="text-center border border-black sticky top-0 z-50 bg-gray-200">
              <tr>
                <th className="border border-black p-2 w-[30px] md:w-[50px]"></th>
                <th className="border border-black p-2 w-[100px]">Time</th>
                <th className="border border-black p-2 w-[60px] md:w-[100px]">
                  Agent
                </th>
                <th className="border border-black p-2 w-[150px]">
                  Agent Name
                </th>
                <th className="border border-black p-2 w-[150px]">Groups</th>
                <th className="border border-black p-2 w-[150px]">Full Log</th>
                <th className="border border-black p-2 w-[150px]">
                  Description
                </th>
                <th className="border border-black p-2 w-[50px]">Level</th>
                <th className="border border-black p-2 w-[100px]">Rule ID</th>
              </tr>
            </thead>
            <tbody>{showAlertsData(allAlertsData)}</tbody>
          </table>
        </div>

        <div>{showAlertsPagination(allAlertsData)}</div>
      </div>
    </div>
  );
};

export default Logs;
