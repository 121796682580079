import React from "react";
import logofooter from "../Images/logo-white.webp";

const Footer = () => {
  return (
    <footer class="bg-white dark:bg-gray-900">
      <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
            <a href="#" class="flex items-center">
              <img
                src={logofooter}
                class="w-18 h-24 me-3"
                alt="SecurWeave Logo"
              />
            </a>
          </div>
          <div class="grid grid-cols-2 gap-8 sm:gap-12 sm:grid-cols-4">
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Product
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a
                    href="https://www.securweave.com/chess-hids"
                    class="hover:underline"
                    target="blank"
                  >
                    Feature
                  </a>
                </li>
                <li>
                  <a href="#" class="hover:underline">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Resources
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a
                    href="https://www.securweave.com/blogs"
                    class="hover:underline"
                    target="blank"
                  >
                    Blogs
                  </a>
                </li>
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    User Guides
                  </a>
                </li>
                <li>
                  <a href="#" class="hover:underline ">
                    Webinars
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Company
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a
                    href="https://www.securweave.com/about-us"
                    class="hover:underline"
                    target="blank"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.securweave.com/careers"
                    class="hover:underline"
                    target="blank"
                  >
                    Join Us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Support
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    Help Centre
                  </a>
                </li>
                <li>
                  <a href="#" class="hover:underline">
                    Chat Support
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a href="#" class="hover:underline">
              SecurWeave™
            </a>
            . All Rights Reserved.
          </span>
          <div class="flex mt-4 sm:justify-center sm:mt-0">
            <a
              href="https://www.linkedin.com/company/securweave-research-labs-pvt-ltd/"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              target="blank"
            >
              <i class="ri-linkedin-fill"></i>
              <span class="sr-only">Linkedin page</span>
            </a>
            <a
              href="https://youtube.com/@securweaveresearchlabs2828?si=Lbhq-FwlOHetf6TQ"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              target="blank"
            >
              <i class="ri-youtube-fill"></i>
              <span class="sr-only">youtube page</span>
            </a>{" "}
            <a
              href="https://x.com/securweave"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              target="blank"
            >
              <i class="ri-twitter-x-line"></i>
              <span class="sr-only">Twitter page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
