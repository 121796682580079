import React, { useEffect, useState } from "react";
import UserNavbar from "./UserNavbar";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../contexts/DashboardContext";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Label,
  ResponsiveContainer,
} from "recharts";
import Map from "./Map";

const MasterDashboard = () => {
  const [systemsData, setSystemsData] = useState([]);
  const [totalAgentsCount, setTotalAgentsCount] = useState(0);
  const [activeAgentsCount, setActiveAgentsCount] = useState(0);
  const [gt10AlertsCount, setGt10AlertsCount] = useState(0);
  const [rootkitAlertsCount, setRootkitAlertsCount] = useState(0);
  const [fiimAlertsCount, setFiimAlertsCount] = useState(0);
  const [attackAlertsCount, setAttackAlertsCount] = useState(0);
  const [byAgentData, setByAgentData] = useState([]);
  const [byGroupData, setByGroupData] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [agentsInfo, setAgentsInfo] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const { setAdmin, setActiveNav } = useDashboard();
  const token = Cookies.get("token");
  const customerid = Cookies.get("customerid");
  const username = Cookies.get("username");
  const role = Cookies.get("role");
  const navigate = useNavigate();

  const getAlertsDataByTypeOrGroup = (category, subcategory) => {
    return axios.get(`/api/system/systems/${category}/${subcategory}`, {
      params: {
        hours: 24,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  const getAlertsDataByChartType = (chartType) => {
    return axios.get(`/api/system/systems/${chartType}`, {
      params: {
        hours: 24,
      },
      headers: {
        "x-auth-token": token,
      },
    });
  };

  const getAgentInfo = (info) => {
    return axios.get(`/api/system/${info}`, {
      headers: {
        "x-auth-token": token,
      },
    });
  };

  useEffect(() => {
    if (token && role) {
      if (role === "1") {
        setAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signin");
    } else {
      axios
        .get("/api/token/isLoggedIn", {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => console.log(res.data))
        .catch(() => navigate("/signin"));
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      axios
        .get(`/api/system/${customerid}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .then((res) => setSystemsData(res.data.systems));
    }
  }, []);

  useEffect(() => {
    setActiveNav("main");
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("gettotalagentscount").then((res) =>
        setTotalAgentsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("getactiveagentscount").then((res) =>
        setActiveAgentsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "gt10").then((res) =>
        setGt10AlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "rootcheck").then((res) =>
        setRootkitAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "syscheck").then((res) =>
        setFiimAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("type", "attack").then((res) =>
        setAttackAlertsCount(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("group", "byagent").then((res) =>
        setByAgentData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByTypeOrGroup("group", "bygroup").then((res) =>
        setByGroupData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByChartType("timeseriesbyagents").then((res) =>
        setTimeSeriesData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAlertsDataByChartType("map").then((res) =>
        setGeoLocationData(res.data)
      );
    }
  }, []);

  useEffect(() => {
    if (role === "3") {
      getAgentInfo("agentsinfo").then((res) => setAgentsInfo(res.data));
    }
  }, []);

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  const chartData =
    timeSeriesData !== "error" &&
    timeSeriesData?.length > 0 &&
    timeSeriesData.flatMap(({ key, timestamp }) =>
      timestamp.map(({ key: time, doc_count }) => ({
        time: new Date(time).toLocaleString(),
        [key]: doc_count,
      }))
    );

  const MasterDashboardDiv = (
    <div className="px-4 lg:w-[80vw] xl:w-[85vw] lg:absolute lg:right-0 lg:px-6 xl:absolute xl:right-0 xl:px-16 xl:pt-20 xl:pb-12 xl:bg-gray-50">
      <h1 className="font-bold text-xl py-8 md:text-3xl">
        Welcome back, <span className="text-[#0055d2]">{username}</span>
      </h1>

      <div>
        <div className="md:flex lg:flex-col lg:items-center xl:flex-row">
          <div className="flex justify-between text-center gap-2 sm:flex-col sm:justify-center sm:w-[40%] sm:px-2 lg:flex-row lg:items-center lg:w-[60%] xl:w-[30%]">
            <div className="w-[50%] sm:w-full">
              <div className="font-semibold  xl:text-lg">Total Systems</div>
              <div className="text-2xl bg-[#0055d2] text-white py-4 rounded-sm mt-2 md:py-8 lg:py-8 lg:text-8xl">
                {totalAgentsCount}
              </div>
            </div>
            <div className="w-[50%] sm:w-full">
              <div className="font-semibold  xl:text-lg">Systems Online</div>
              <div className="text-2xl bg-[#6ab65d] text-white py-4 rounded-sm mt-2 md:py-8 lg:py-8 lg:text-8xl">
                {activeAgentsCount}
              </div>
            </div>
          </div>

          <div className="mt-6 sm:w-[60%] xl:w-[70%] sm:p-2">
            <h1 className="font-semibold sm:text-center md:text-xl xl:text-xl">
              Last 24 hours stats
            </h1>
            <div className="flex flex-wrap justify-between text-center gap-2 pt-4">
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Alert Level &gt; 10
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/gt10")}
                >
                  {gt10AlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Rootkit Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/rootcheck")}
                >
                  {rootkitAlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  FIIM Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/syscheck")}
                >
                  {fiimAlertsCount}
                </div>
              </div>
              <div className=" w-[48%] rounded-sm mb-4 lg:w-[48%] xl:w-[23%]  border border-black cursor-pointer">
                <h1 className="font-bold text-black py-2 lg:text-xs xl:text-lg">
                  Attack Alerts
                </h1>
                <div
                  className="text-4xl py-2 rounded-sm mt-2 text-red-500 font-extrabold sm:py-8 lg:text-5xl border-t border-black"
                  onClick={() => navigate("/logs/systems/attack")}
                >
                  {attackAlertsCount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:flex xl:flex-col">
          <div className="xl:flex">
            <div className="w-full xl:pr-16 xl:flex xl:flex-col xl:justify-evenly">
              <div className="w-full text-[0.5rem] xl:text-[1rem]">
                {chartData !== "error" && chartData?.length > 0 ? (
                  <ResponsiveContainer
                    aspect={1.75}
                    height="100%"
                    width="100%"
                    className="flex justify-center py-10"
                  >
                    <AreaChart data={chartData}>
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="time" allowDuplicatedCategory={false}>
                        <Label
                          fill="black"
                          value="time"
                          position="insideLeft"
                          offset={-5}
                        />
                      </XAxis>
                      <YAxis>
                        <Label
                          fill="black"
                          angle={270}
                          value="alerts count"
                          position={"insideLeft"}
                        />
                      </YAxis>
                      <Tooltip />
                      <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                      />
                      <Label />
                      {timeSeriesData.map(({ key }) => {
                        const colorr = `#${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`;
                        return (
                          <Area
                            dot={false}
                            key={key}
                            type="monotone"
                            dataKey={key}
                            name={key}
                            stroke={colorr}
                            fill={colorr}
                          />
                        );
                      })}
                    </AreaChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="flex justify-center items-center text-red-400">
                    No Data Found!
                  </div>
                )}
              </div>

              {agentsInfo.length > 0 ? (
                <div class="overflow-x-scroll overflow-y-auto max-h-[50vh] text-[0.75rem] xl:overflow-auto">
                  <table class="w-[100%] text-left border-separate border-spacing-0 border-black">
                    <thead className="border border-black sticky top-0 z-50 bg-gray-200">
                      <tr>
                        <th className="border border-black p-2">ID</th>
                        <th className="border border-black p-2">Name</th>
                        <th className="border border-black p-2">IP Address</th>
                        <th className="border border-black p-2">
                          Operating System
                        </th>
                        <th className="border border-black p-2">Status</th>
                        <th className="border border-black p-2">last seen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentsInfo.map((data) => {
                        return (
                          <tr key={data.id} className="border border-black">
                            <td className="border border-black p-2">
                              {data.id}
                            </td>
                            <td className="border border-black max-w-max p-2">
                              <a
                                href={`/systemdashboard/${data.name}`}
                                className="text-[#0078a3] whitespace-nowrap"
                              >
                                {data.name}
                              </a>
                            </td>
                            <td className="border border-black whitespace-nowrap p-2">
                              {data.ip}
                            </td>
                            <td className="border border-black p-2">
                              {data.os ? data.os.name : "-"}{" "}
                              {data.os ? data.os.version : ""}
                            </td>

                            <td className="border border-black p-2">
                              <div></div>
                              {data.status}
                            </td>
                            <td className="border border-black whitespace-nowrap p-2">
                              {data.lastKeepAlive
                                ? new Date(data.lastKeepAlive).toLocaleString()
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  loading...
                </div>
              )}
            </div>

            <div className="w-full md:flex md:justify-around xl:flex-col">
              <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                <div className="my-2 xl:text-xl">
                  Top Systems Generating Alerts
                </div>
                <div className="w-full text-[0.5rem] xl:text-[1rem]">
                  {byAgentData !== "error" && byAgentData?.length > 0 ? (
                    <ResponsiveContainer aspect={1} width="100%" height={300}>
                      <PieChart>
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          wrapperStyle={{
                            fontSize: "0.5rem",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "40vh",
                            overflowY: "auto",
                          }}
                        />
                        <Pie
                          dataKey="doc_count"
                          nameKey="key"
                          isAnimationActive={false}
                          data={byAgentData}
                          innerRadius="50%"
                          outerRadius="100%"
                          stroke=""
                          fill="#8884d8"
                          labelLine={false}
                        >
                          {byAgentData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>

                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="flex justify-center items-center text-red-400">
                      No Data Found!
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col items-center w-full md:w-1/2 xl:w-full">
                <div className="my-2 xl:text-xl">Top Alert Groups</div>
                <div className="w-full text-[0.5rem] xl:text-[1rem]">
                  {byGroupData !== "error" && byGroupData?.length > 0 ? (
                    <ResponsiveContainer aspect={1} width="100%" height={325}>
                      <PieChart>
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          wrapperStyle={{
                            fontSize: "0.5rem",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "40vh",
                            overflowY: "auto",
                          }}
                        />
                        <Pie
                          dataKey="doc_count"
                          nameKey="key"
                          data={byGroupData}
                          innerRadius="50%"
                          outerRadius="100%"
                          stroke=""
                          fill="#8884d8"
                          labelLine={false}
                        >
                          {byGroupData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>

                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="flex justify-center items-center text-red-400">
                      No Data Found!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full xl:flex xl:flex-col xl:items-center">
            <div className="my-2 xl:text-xl">Source IP Addresses</div>
            <div className="w-full">
              {geoLocationData !== "error" && geoLocationData?.length > 0 ? (
                <Map geoLocationData={geoLocationData} />
              ) : (
                <div className="flex justify-center items-center text-red-400">
                  No Data Found!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="overflow-hidden lg:flex">
      <UserNavbar />

      {role !== "1" ? (
        <>
          {systemsData.length > 0 ? (
            MasterDashboardDiv
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              No Agents Added
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              fontSize: "large",
            }}
          >
            <li
              style={{
                margin: "0 1rem",
                background: "#030B3A",
                padding: "0.5rem",
                color: "white",
                cursor: "pointer",
              }}
            >
              <a onClick={() => navigate("/addcustomer")}>Add Customer</a>
            </li>
            <li
              style={{
                margin: "0 1rem",
                background: "#030B3A",
                padding: "0.5rem",
                color: "white",
                cursor: "pointer",
              }}
            >
              <a onClick={() => navigate("/addlicense")}>Add License</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MasterDashboard;
