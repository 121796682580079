import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import logoheader from "../Images/logo-white.webp";
import { useDashboard } from "../contexts/DashboardContext";

const UserNavbar = () => {
  const navigate = useNavigate();
  const username = Cookies.get("username");
  const role = Cookies.get("role");
  const { setAdmin, activeNav } = useDashboard();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="nav-parent bg-white text-black flex sticky top-0 lg:flex-col justify-between px-4 py-2 w-full lg:h-[100vh] lg:w-[20vw] xl:w-[15vw] lg:fixed xl:fixed xl:border xl:border-black xl:border-r-1 xl:py-6 xl:z-30">
        <div className="nav-left flex lg:flex-col justify-center items-center gap-6 lg:gap-24">
          <div>
            <img
              className="cursor-pointer w-18 h-16 lg:w-36 lg:h-24 mix-blend-exclusion"
              src={logoheader}
              onClick={() => navigate("/")}
            ></img>
          </div>
          <div className="hidden lg:block">
            <div className="flex lg:flex-col gap-4 text-lg lg:gap-8 md:text-sm lg:text-md xl:text-xl">
              {role === "1" ? (
                <Link to={"/dashboard"}>Home</Link>
              ) : (
                <>
                  <Link
                    to={"/dashboard"}
                    className={`${
                      activeNav === "main" ? "bg-[#b2ebff]" : null
                    } hover:bg-[#b2ebff] p-2 rounded-md`}
                  >
                    <i class="ri-function-fill mr-2 "></i>Dashboard
                  </Link>
                  <Link
                    to={"/systemdashboard"}
                    className={`${
                      activeNav === "system" ? "bg-[#b2ebff]" : null
                    } hover:bg-[#b2ebff] p-2 rounded-md`}
                  >
                    <i class="ri-apps-2-add-fill mr-2"></i>System View
                  </Link>
                  <Link
                    to={"/reports"}
                    className={`${
                      activeNav === "reports" ? "bg-[#b2ebff]" : null
                    } hover:bg-[#b2ebff] p-2 rounded-md`}
                  >
                    <i class="ri-file-chart-fill mr-2"></i>Reports
                  </Link>
                  <Link
                    to={"/configuration"}
                    className={`${
                      activeNav === "config" ? "bg-[#b2ebff]" : null
                    } hover:bg-[#b2ebff] p-2 rounded-md`}
                  >
                    <i class="ri-tools-fill mr-2"></i>Configuration
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="nav-right flex justify-center items-center gap-4 lg:flex-col xl:text-xl">
          <div className="relative lg:hidden">
            <div onClick={toggleMenu} className="block lg:hidden text-2xl">
              <i class="ri-menu-line"></i>
            </div>
            <nav
              className={`absolute top-8 right-0 z-[100] w-[175px] bg-white shadow-lg rounded-md ${
                isOpen ? "block" : "hidden"
              } gap-8`}
            >
              <ul className="flex flex-col p-4">
                <li className="py-2 mb-2">
                  <Link
                    to={"/dashboard"}
                    className={`${
                      activeNav === "main" ? "bg-[#b2ebff]" : null
                    }  p-2 rounded-md`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="py-2 mb-2">
                  <Link
                    to={"/systemdashboard"}
                    className={`${
                      activeNav === "system" ? "bg-[#b2ebff]" : null
                    }  p-2 rounded-md`}
                  >
                    System View
                  </Link>
                </li>
                <li className="py-2 mb-2">
                  <Link
                    to={"/reports"}
                    className={`${
                      activeNav === "reports" ? "bg-[#b2ebff]" : null
                    }  p-2 rounded-md`}
                  >
                    Reports
                  </Link>
                </li>
                <li className="py-2 mb-2">
                  <Link
                    to={"/configuration"}
                    className={`${
                      activeNav === "config" ? "bg-[#b2ebff]" : null
                    }  p-2 rounded-md`}
                  >
                    Configuration
                  </Link>
                </li>
                <li className="py-2">
                  <div
                    className="text-sm bg-red-500 text-white p-2 rounded-md text-center"
                    onClick={() => {
                      Cookies.remove("token");
                      Cookies.remove("customerid");
                      Cookies.remove("username");
                      Cookies.remove("customerkey");
                      Cookies.remove("role");
                      setAdmin(false);
                      navigate("/signin");
                    }}
                  >
                    <i class="ri-logout-box-line mr-2"></i>
                    Sign Out
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className=" justify-center items-center hidden lg:flex">
            <i class="ri-shield-user-fill mr-2"></i> {username}
          </div>
          <div
            className="px-6 py-3 bg-black text-white rounded-lg cursor-pointer hidden lg:flex hover:bg-red-600"
            onClick={() => {
              Cookies.remove("token");
              Cookies.remove("customerid");
              Cookies.remove("username");
              Cookies.remove("customerkey");
              Cookies.remove("role");
              setAdmin(false);
              navigate("/signin");
            }}
          >
            <i class="ri-logout-box-line mr-2"></i>
            Sign Out
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNavbar;
