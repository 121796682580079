import { useState, createContext, useContext } from "react";
export const DashboardContext = createContext();
export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = (props) => {
  const [licenseInfo, setLicenseInfo] = useState("");
  const [agentsList, setAgentsList] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [activeNav, setActiveNav] = useState("");

  return (
    <DashboardContext.Provider
      value={{
        licenseInfo,
        setLicenseInfo,
        agentsList,
        setAgentsList,
        admin,
        setAdmin,
        customers,
        setCustomers,
        licenses,
        setLicenses,
        activeNav,
        setActiveNav,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
